<template>
  <nav class="NavigationSecondaryRail">
    <!-- Player menu -->
    <section v-if="!$store.state.player.isObserver">
      <NavigationSecondaryItem
        routeName="Game | Life"
        text="Life"
        iconClass="heart"
        routeParams="{'gameId': $store.state.game.game_obj.publicId}">
      </NavigationSecondaryItem>
      <NavigationSecondaryItem
        v-if="Object.keys($store.state.game.game_obj.players).length > 1"
        routeName="Game | Commander Damage"
        text="Damage"
        iconClass="helmet-battle"
        routeParams="{'gameId': $store.state.game.game_obj.publicId}">
      </NavigationSecondaryItem>
      <NavigationSecondaryItem
        routeName="Game | Counters"
        text="Counters"
        iconClass="coins"
        routeParams="{'gameId': $store.state.game.game_obj.publicId}">
      </NavigationSecondaryItem>
      <NavigationSecondaryItem
        routeName="Game | History"
        text="History"
        iconClass="scroll-old"
        routeParams="{'gameId': $store.state.game.game_obj.publicId}">
      </NavigationSecondaryItem>
      <NavigationSecondaryItem
        routeName="Game | Settings"
        text="Settings"
        iconClass="cog"
        routeParams="{'gameId': $store.state.game.game_obj.publicId}">
      </NavigationSecondaryItem>
    </section>

    <!-- Observer menu -->
    <section  v-if="$store.state.player.isObserver">
      <NavigationSecondaryItem
        routeName="Observer | Life"
        text="Life"
        iconClass="heart"
        routeParams="{'gameId': $store.state.game.game_obj.publicId}">
      </NavigationSecondaryItem>
      <NavigationSecondaryItem
        v-if="Object.keys($store.state.game.game_obj.players).length > 1"
        routeName="Observer | Commander Damage"
        text="Damage"
        iconClass="helmet-battle"
        routeParams="{'gameId': $store.state.game.game_obj.publicId}">
      </NavigationSecondaryItem>
      <NavigationSecondaryItem
        routeName="Observer | History"
        text="History"
        iconClass="scroll-old"
        routeParams="{'gameId': $store.state.game.game_obj.publicId}">
      </NavigationSecondaryItem>
      <NavigationSecondaryItem
        routeName="Observer | Settings"
        text="Settings"
        iconClass="cog"
        routeParams="{'gameId': $store.state.game.game_obj.publicId}">
      </NavigationSecondaryItem>
    </section>
  </nav>
</template>

<script>
  import NavigationSecondaryItem from '@/components/NavigationSecondaryItem.vue';
  export default {
    name: 'NavigationSecondaryRail',
    components: {
      NavigationSecondaryItem,
    },
    data: () => {
      return {

      }
    },
    methods: {
      click: function() {

      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.NavigationSecondaryRail {
  section {
    display: flex;
    position: fixed;
    z-index: 10;
    background: @app-color-primary;
    background: @app-color-conic;

    
    height: 70px;
    width: 100vw;
    max-width: 600px;
    
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0;
    bottom: 0;
    .corners--rounded();
    margin-bottom: 15px;


    @media @tablet-down {
      left: 0px;
      margin-bottom: 0px;
      max-width: 100vw;
      .corners--straight();
    }    
  }
}
</style>
