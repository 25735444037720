<template>
  <Tile z="1" class="join">
    <h2 class="new">
      Enter Game Code
    </h2>

   <div class="settings">
    <label>
      <input
        class="font-style--readable"
        v-on:keyup="enforceMaxLength"
        v-model="gameId"
        type="text"
        name="gameId"
        maxlength="6"
        autocomplete="off"
        size="6"/>
    </label>
   </div>
   <div class="msg">
    <div v-if="errorMsg" class="msg--error">{{errorMsg}}</div>
    <div v-if="searching" class="msg--searching"><i class="fa fa-spinner fa-spin"></i></div>
  </div>
   <Button :callback="nextStep">Join Game</Button>
 </Tile>
</template>

<script>
  import Button from '@/components/Button';
  import Tile from '@/components/Tile';
  import router from '@/router';
  export default {
    name: 'New',
    components: {
      Button,
      Tile
    },
    data: () => {
      return {
        gameId: "",
        errorMsg: "",
        searching: false,
      }
    },
    mounted() {
      if(this.$route.query.code) {
        this.gameId = this.$route.query.code.slice(0,6);
        this.nextStep();
      }
    },
    methods: {
      // This causes a little bit of a hack in mobile.
      // the extra text is there, but hidden.
      enforceMaxLength(e) {
        if(e.target.value.length > 6) {
          e.target.value = e.target.value.substr(0, 6);
        }
      },
      async nextStep() {
        //this.$store.commit('get_game', this.gameId.toUpperCase());
        this.errorMsg = "";
        this.searching = true;

        let response = await this.$store.dispatch('get_game', this.gameId.toUpperCase());
        this.searching = false;

        // If we have a valid response, go to the game
        if(response) {
          this.errorMsg = "";
          this.$store.commit('set_joining', true);
          if(!response.settings.acceptingPlayers) {
            // Lobby redirects to the rest of the game
            router.push(`/game/${this.gameId.toUpperCase()}/lobby`);
          } else {
            if(this.$store.state.game.game_obj.players[localStorage.getItem('default--player_public')]) {

              // If a player is already in the game, go to the lobby
              router.push(`/game/${this.gameId.toUpperCase()}/lobby`);
            } else {

              // If a player is not yet in the game, make the profile.
              let response = await this.$store.dispatch('join_game', {
                name: localStorage.getItem('default--player_name'),
                avatar: localStorage.getItem('default--player_avatar'),
                publicId: localStorage.getItem('default--player_public'),
                secretId: localStorage.getItem('default--player_secret'),
              });

              // TODO - If failure, show an error message
              if(response === 'Success') {
                console.log(response);
                router.push(`/game/${this.$store.state.game.game_obj.publicId}/lobby`)
              }
            }
            
          }
        } else {
          this.errorMsg = "Game Not Found";
        }
      }
    }
  }
</script>

<style scoped lang="less">
  input {
    text-transform: uppercase;
    text-align: center;
    font-size: 3rem;
    background: @background-color-primary;
    border: none;
    outline: none;
    color: white;
    padding: 15px;
    max-width: 250px;
    box-sizing: border-box;
  }

  .msg {
    box-sizing: border-box;
    height: 20px;
    padding: 5px;
    color: white;

    &--error {
      .error-color()
    }
    
  }
</style>