import { render, staticRenderFns } from "./NavigationSecondaryItem.vue?vue&type=template&id=a49e2aac&scoped=true&"
import script from "./NavigationSecondaryItem.vue?vue&type=script&lang=js&"
export * from "./NavigationSecondaryItem.vue?vue&type=script&lang=js&"
import style0 from "./NavigationSecondaryItem.vue?vue&type=style&index=0&id=a49e2aac&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a49e2aac",
  null
  
)

export default component.exports