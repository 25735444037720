<template>
  <nav class="NavigationSecondaryItem">
    <router-link active-class="active" class="nav-contents" :to="{name: routeName, params: routeParams}">
      <font-awesome-icon class='icon' :icon="['far', iconClass]" />
      <span class="label">{{text}}</span>
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'NavigationSecondaryItem',
  props: ['routeName', 'text', 'iconClass', 'routeParams'],
  data: () => {
    return {

    }
  },
  methods: {
    click: function() {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.NavigationSecondaryItem {
  flex-basis: 0;
  flex-grow: 1;

  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:first-of-type {
    a {
      border-radius: 5px 0 0 0;
    }
  }

  &:last-of-type {
    a {
      border-radius: 0 5px 0 0;
    }
  }

  @media @tablet-down {
    &:first-of-type {
      a {
        .corners--straight();
      }
    }

    &:last-of-type {
      a {
        .corners--straight();
      }
    }
  }






  * {
    color: @font-color-primary;
    text-decoration: none;
  }

  a {
    width: 100%;
    height: 100%;

    &.active {
      border-top: 1px solid white;
    }


    * {
      transition: opacity @animation-duration--fast;
      opacity: .4;
    }

    &.active * {
      opacity: 1;
    }

    .label {
      font-size: .75em;
    }
  }

  .nav-contents {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
