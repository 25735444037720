<template>
  <div class="game">
    <div v-if="!$store.state.player.isConnected">
      <router-view/>
    </div>
    <div v-else>
      <div class="gameContainer">
        <div v-if="$store.state.game.game_obj.hasStarted">
          <div class="sub-nav">
            <NavigationSecondaryRail></NavigationSecondaryRail>
          </div>
        </div>
        <div id="gameContent" class="gameContent fancyScrollbar">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

        

<script>
  import store from '@/main.js';
  import NavigationSecondaryRail from '@/components/NavigationSecondaryRail.vue';
  export default {
    name: 'Game',
    components: {
      NavigationSecondaryRail
    },
    beforeRouteLeave(to, from, next) {
      store.commit('init_game');
      store.commit('init_player');
      next();
    },
  }
</script>

<style lang="less">
.gameContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .gameContent {
    max-width: 600px;
    padding-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

h2 {
  color: white;
}

h3 {
  color: white;
}

</style>