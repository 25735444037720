<template>
  <div class="about">
    <Tile z="1">
      <div class="font-style--readable">
        <h2>About Us</h2>
        <font-awesome-icon :icon="['far', 'helmet-battle']" size="4x" class="font-color--primary" />
        <p class="font-style--readable">We built Health Potion because we saw a need for better applications in the Commander space. There are many digital life trackers out there, but very few meet the specific needs of our format. The majority of these apps involve using a single device to keep track of life, and after the events of 2020 and 2021, we decided it's safer to go with a distributed approach; each player tracking their own things. This shift also spreads out the burden of bookkeeping, so all players can enjoy the game equally.</p>
      </div>
    </Tile>
    <Tile z="1">
      <div class="font-style--readable">
        <h2>Our Guiding Principles</h2>
        <font-awesome-icon :icon="['far', 'scroll-old']" size="4x" class="font-color--primary" />
        <p class="font-style--readable">Before we started building, we decided on three guiding principles that influence every decision we make. If we ever stray from these principles, just let us know, and we'll make it right.</p>
        
        <p class="font-style--readable">1. Make games of Commander easier to play</p>
        <p class="font-style--readable">2. Using the app should be a joy, not a hassle</p>
        <p class="font-style--readable">3. Do not be the center of attention, let players focus on the game</p>
      </div>
    </Tile>
    <Tile z="1">
      <div class="font-style--readable">
        <h2>Special Thanks</h2>
        <font-awesome-icon :icon="['far', 'star']" size="4x" class="font-color--primary" />
        <p class="thankyou">
          <a class="font-style--readable" target="_blank" rel="noopener noreferrer" href="https://twitter.com/InklinCustoms">Inklin Customs</a>for our logo design
        </p>
        <p class="thankyou">
          <a class="font-style--readable" target="_blank" rel="noopener noreferrer" href="https://scryfall.com/">Scryfall</a>for their image & searching API
        </p>
        <p class="thankyou">
          <a class="font-style--readable" target="_blank" rel="noopener noreferrer" href="https://keyrune.andrewgioia.com/index.html">Andrew Gioia</a>for his mana & set symbol libraries
        </p>
      </div>
    </Tile>
  </div>
</template>

<script>
  import Tile from "@/components/Tile.vue";
    export default {
    name: 'About',
    components: {
      Tile,
    }
  }
  </script>

<style scoped lang="less">
.about {
  a {
    margin: 3px;
    color: #ccc;
    
    
    cursor: pointer;
    
    &:hover {
      color: @app-color-primary-1;
    }
  }
  .thankyou {
    font-family: @font-style--readable;
  }
}

  
</style>
