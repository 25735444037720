<template>
  <div class="New">

    <!-- When the game is being created -->
    <div id="gameCreating" v-if="isCreating">
      <h3 class="font-color--primary font-size--large">Creating your game</h3>
      <div id="spinner" class="spinning">
        <div id="bulb"></div>
      </div>
      <div id="subheader" class="font-color--primary">Ready in just a second...</div>
    </div>

    <!-- When the game is ready -->
    <div id="gameReady" v-if="!isCreating && $store.state.game.game_obj.publicId">
      <h3 class="font-color--primary font-size--large">Your game is ready</h3>
      <QRCode :gameId="$store.state.game.game_obj.publicId"></QRCode>

      <h3 class="font-color--primary">Share this code to invite other players to your game</h3>

      <Button :callback="startGame">
        Start Game
      </Button>
    </div>
  </div>
</template>

<script>
  import Button from "@/components/Button.vue";
  import router from '@/router/index.js';
  import QRCode from "@/components/QRCode.vue";
  export default {
    name: 'New',
    components: {
      Button,
      QRCode
    },
    data: () => {
      return {
        isCreating: true,
      }
    },
    mounted() {
      this.createGame();
    },
    methods: {
      async createGame() {
        this.isCreating = true;
        const createPromise = new Promise(resolve => setTimeout(resolve, 2000));

        let newGamePromise = this.$store.dispatch('create_game', {format:'commander', startingLife:40});

        Promise.all([newGamePromise,createPromise]).then((resultList) => {
          const newGameId = resultList[0];
          if(newGameId) {
            this.isCreating = false;
            this.joinGame();
          } else {
            alert("Failed to create game! Please try again later.");
          }
        });
      },
      async joinGame() {
        const name = localStorage.getItem('default--player_name');
        const avatar = localStorage.getItem('default--player_avatar');

        // TODO - Update to use the more usable "set_plyaer"
        this.$store.commit('set_name', name);
        this.$store.commit('set_avatar', avatar);

        const commander0 = localStorage.getItem('default--player_commander_0') ? JSON.parse(localStorage.getItem('default--player_commander_0')).id : undefined;
        const commander1 = localStorage.getItem('default--player_commander_1') ? JSON.parse(localStorage.getItem('default--player_commander_1')).id : undefined;

        const commanderArray = [];

        if(commander0) {
          commanderArray.push(commander0);
        }

        if(commander1) {
          commanderArray.push(commander1);
        }

        let response = await this.$store.dispatch('join_game', {
          "name": name,
          "avatar": avatar,
          "commanderList": commanderArray,
          "publicId": localStorage.getItem('default--player_public'),
          "secretId": localStorage.getItem('default--player_secret')
        });

        // TODO - If failure, show an error message
        if(response === 'Success') {
          console.log(response);
          console.log(this.$store.state.game.game_obj);
          
        }
      },
      startGame() {
        router.push(`/game/${this.$store.state.game.game_obj.publicId}/life`);
      }
    }
  }
</script>
<style scoped lang="less">
.New {

  #spinner {
    height: 200px;
    width: 200px;
    border-radius: 200px;
    border: 5px solid @app-color-primary-1;
    margin: auto;

    animation-duration: 3s;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: linear;

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    #bulb {
      height: 30px;
      width: 30px;
      background: @app-color-primary-1;
      background: @app-color-conic;
      border-radius: 30px;
      margin-top: 30px;
    }
  }

  #subheader {
    margin-top: 15px;
  }
}
</style>  